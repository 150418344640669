import { ReactNode } from 'react';
import { DataLayerEvents } from '../interfaces/tracking';
import { ExpansionStatus } from '@ncs-frontend-monorepo/availability';

interface IFibreOpticExpansionContent {
  dynamicLink: DataLayerEvents.PreorderLink | DataLayerEvents.RegisterLink;
  dynamicStatus: ExpansionStatus;
  textContent: {
    headline: string;
    informationText: ReactNode | string;
    buttonText: string;
  };
}

export const getFibreOpticExpansionContent = (
  availabilityDate: string | null,
  plannedBandWidth: string,
  status: ExpansionStatus,
): IFibreOpticExpansionContent => {
  switch (status) {
    case ExpansionStatus.AREA_PLANNED:
      return getPropsForAreaPlanned(availabilityDate, plannedBandWidth);
    case ExpansionStatus.PRE_MARKETING:
      return getPropsForPreMarketing(availabilityDate, plannedBandWidth);
    case ExpansionStatus.UNDER_CONSTRUCTION:
      return getPropsForUnderConstruction(availabilityDate, plannedBandWidth);
  }
};

const getPropsForAreaPlanned = (
  availabilityDate: string,
  plannedBandWidth: string,
): IFibreOpticExpansionContent => {
  return {
    dynamicLink: DataLayerEvents.RegisterLink,
    dynamicStatus: ExpansionStatus.AREA_PLANNED,
    textContent: {
      headline: 'Jetzt vormerken lassen!',
      informationText: availabilityDate ? (
        <>
          Wir prüfen den Glasfaserausbau an deiner Adresse. Voraussichtlich ab
          dem <strong>{availabilityDate}</strong> wird Glasfaser mit bis zu{' '}
          <strong>{plannedBandWidth}</strong> verfügbar sein. Du möchtest auf
          dem Laufenden bleiben? Melde dich für unseren Newsletter an.
        </>
      ) : (
        <>
          Wir prüfen den Glasfaserausbau mit bis zu{' '}
          <strong>{plannedBandWidth}</strong> an deiner Adresse. Du möchtest auf
          dem Laufenden bleiben? Melde dich für unseren Newsletter an.
        </>
      ),
      buttonText: 'Jetzt informieren',
    },
  };
};

const getPropsForPreMarketing = (
  availabilityDate: string,
  plannedBandWidth: string,
): IFibreOpticExpansionContent => {
  return {
    dynamicLink: DataLayerEvents.PreorderLink,
    dynamicStatus: ExpansionStatus.PRE_MARKETING,
    textContent: {
      headline: 'Jetzt Glasfaser-Tarif sichern!',
      informationText: availabilityDate ? (
        <>
          Sichere dir deinen Glasfaseranschluss mit bis zu{' '}
          <strong>{plannedBandWidth}</strong>! Wenn genug Interessenten
          zusammenkommen, wird der Ausbau voraussichtlich im{' '}
          <strong>{availabilityDate}</strong> abgeschlossen sein. Erfahre mehr
          über den Ausbau und was nötig ist, damit auch deine Adresse
          angeschlossen wird.
        </>
      ) : (
        <>
          Sichere dir deinen Glasfaseranschluss mit bis zu{' '}
          <strong>{plannedBandWidth}</strong>! Wenn genug Interessenten
          zusammenkommen, bauen wir aus. Erfahre mehr über den Ausbau und was
          nötig ist, damit auch deine Adresse angeschlossen wird.
        </>
      ),
      buttonText: 'Jetzt informieren und vorbestellen',
    },
  };
};

const getPropsForUnderConstruction = (
  availabilityDate: string,
  plannedBandWidth: string,
): IFibreOpticExpansionContent => {
  return {
    dynamicLink: DataLayerEvents.PreorderLink,
    dynamicStatus: ExpansionStatus.UNDER_CONSTRUCTION,
    textContent: {
      headline: 'Jetzt Glasfaser-Tarif sichern!',
      informationText: availabilityDate ? (
        <>
          Sichere dir deinen Glasfaseranschluss mit bis zu{' '}
          <strong>{plannedBandWidth}</strong>! Voraussichtlich im{' '}
          <strong>{availabilityDate}</strong> wird der Ausbau bei dir
          abgeschlossen sein. Erfahre mehr über den Ausbau und was nötig ist,
          damit auch deine Adresse angeschlossen wird.
        </>
      ) : (
        <>
          Sichere dir deinen Glasfaseranschluss mit bis zu{' '}
          <strong>{plannedBandWidth}</strong>! Erfahre mehr über den Ausbau und
          was nötig ist, damit auch deine Adresse angeschlossen wird.
        </>
      ),
      buttonText: 'Jetzt informieren und vorbestellen',
    },
  };
};
