import { create } from 'zustand';

interface YoungStore {
  isYoungChecked: string | null;
  setIsYoungChecked: (value: string | null) => void;
}

export const useYoungStore = create<YoungStore>((set) => ({
  isYoungChecked: null,
  setIsYoungChecked: (value) => set({ isYoungChecked: value }),
}));
