import {
  Button,
  Grid,
  IconAttention,
  Separator,
  Spacer,
  Text,
} from 'dss-ui-library';
import React from 'react';
import { AvailabilityStatus } from '../../../interfaces/tracking';
import { pushAvailability } from '../../../utils/dataLayer/availability';
import { getFibreOpticExpansionContent } from '../../../utils/getFibreOpticExpansionContent';
import { Media } from '@ncs-frontend-monorepo/utils';
import { useAvailabilityContext } from '../Context';
import styles from './FibreOpticExpansion.module.scss';
import {
  ExpansionStatus,
  ObjectInformation,
  useAvailability,
} from '@ncs-frontend-monorepo/availability';

interface FibreOpticExpansion {
  objectInformation: ObjectInformation;
  buttonColor: 'blue' | 'red';
  displayStyle: 'nextto' | 'underneath';
  embedded?: boolean;
}

const { eventLinks } = pushAvailability();

const FibreOpticExpansion: React.FC<FibreOpticExpansion> = ({
  objectInformation,
  buttonColor,
  displayStyle,
  embedded = false,
}) => {
  const {
    address,
    availability: { maxDownload },
  } = useAvailability();
  const { availabilityType } = useAvailabilityContext();
  const isAreaPlanned =
    objectInformation.status === ExpansionStatus.AREA_PLANNED;
  const e2e = `ftth-landingpage-tracking-${objectInformation.status.toLowerCase()}`;

  const { dynamicLink, dynamicStatus, textContent } =
    getFibreOpticExpansionContent(
      objectInformation.availabilityDateHalfYear,
      objectInformation.plannedBandwidth || objectInformation.maxBandwidth,
      objectInformation.status,
    );

  const handleButtonClick = () => {
    const expansionLink = objectInformation?.landingpagePk;

    eventLinks({
      event: dynamicLink,
      status: AvailabilityStatus.AvailablePresale,
      expansionStatus: dynamicStatus,
      zipCode: address.zipCode,
      checkSource: availabilityType,
      maxDownload,
      targetPage: expansionLink,
    });

    window.location.href = expansionLink;
  };

  const renderContent = () => {
    switch (displayStyle) {
      case 'nextto':
        return (
          <>
            <Grid.Row>
              <Grid.Column
                s={isAreaPlanned ? 8 : 6}
                className={styles.infoText}
              >
                <Spacer r={2}>
                  <IconAttention color="blue" width={24} height={24} />
                </Spacer>
                <Text e2e="presale-info-text" appearance="t5" block>
                  {textContent.informationText}
                </Text>
              </Grid.Column>
              <Grid.Column s={isAreaPlanned ? 4 : 6} centerS hEndS>
                <Media lessThan="s">
                  <Spacer t={2} block />
                </Media>
                <Button
                  variant="primary"
                  color={buttonColor}
                  e2e={e2e}
                  onClick={handleButtonClick}
                >
                  {textContent.buttonText}
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Spacer t={3} b={3} block>
              <Separator
                height={4}
                color={embedded ? 'white' : 'superlight-grey'}
              />
            </Spacer>
          </>
        );

      case 'underneath':
        return (
          <Grid.Row>
            <Grid.Column>
              <Text appearance="t5" block>
                {textContent.informationText}
              </Text>
            </Grid.Column>
            <Grid.Column hEndS>
              <Spacer t={2} block />
              <Button
                variant="primary"
                color={buttonColor}
                e2e={e2e}
                onClick={handleButtonClick}
              >
                {textContent.buttonText}
              </Button>
            </Grid.Column>
          </Grid.Row>
        );
    }
  };

  return (
    <Grid fullWidth>
      <Grid.Row>
        <Grid.Column>
          <Spacer b={2}>
            <Text appearance="t2_2">{textContent.headline}</Text>
          </Spacer>
        </Grid.Column>
      </Grid.Row>
      {renderContent()}
    </Grid>
  );
};

export { FibreOpticExpansion };
