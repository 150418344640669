import { Spacer, Text } from 'dss-ui-library';
import React from 'react';
import { getEnv } from '@ncs-frontend-monorepo/utils';
import { AddressRow } from '../../Elements/AddressRow';
import { FibreOpticExpansion } from '../../Elements/FibreOpticExpansion';
import { FormFieldValues } from '../Form';
import { useAvailability } from '@ncs-frontend-monorepo/availability';

interface FTTHPlannedFallback {
  address: FormFieldValues;
  hasPreselectedProduct?: boolean;
  onChangeAddressClick?(): void;
}

const FTTHPlannedFallback: React.FC<FTTHPlannedFallback> = ({
  address,
  hasPreselectedProduct = false,
  onChangeAddressClick,
}) => {
  const {
    availability: { objectInformation },
  } = useAvailability();

  return (
    <>
      <Spacer b={1} r={4} block>
        <Text appearance="t2" color="blue">
          {hasPreselectedProduct
            ? 'Schade, dein Wunschtarif ist an dieser Adresse aktuell nicht verfügbar'
            : `Schade, an deiner Adresse ist ${
                getEnv().SITE
              } noch nicht verfügbar`}
        </Text>
      </Spacer>
      <AddressRow
        address={address}
        onChangeAddressClick={onChangeAddressClick}
      />
      <FibreOpticExpansion
        buttonColor="red"
        displayStyle="underneath"
        objectInformation={objectInformation}
      />
    </>
  );
};

export { FTTHPlannedFallback };
