import dynamic from 'next/dynamic';
import React, { useEffect } from 'react';
import { useModalContext } from '../../../ModalContext';
import { useAvailabilityContext } from '../../Context';
import { pushAvailability } from '../../../../utils/dataLayer/availability';
import {
  AvailabilityStatus,
  DataLayerEvents,
} from '../../../../interfaces/tracking';
import { ProductSelector } from '../../Elements/ProductSelector';
import styles from './OptionSelect.module.scss';
import { IconAttention, Spacer, Text } from 'dss-ui-library';
import { FTTHPlannedFallback } from '../FTTHPlannedFallback';
import { AddressRow } from '../../Elements/AddressRow';
import { checkMandantType, getEnv } from '@ncs-frontend-monorepo/utils';
import {
  ExpansionStatus,
  hasCableTV,
  Technology,
  useAvailability,
} from '@ncs-frontend-monorepo/availability';
import { useOrder } from '@ncs-frontend-monorepo/order';

const FibreOpticExpansion = dynamic(() =>
  import('../../Elements/FibreOpticExpansion').then(
    ({ FibreOpticExpansion }) => FibreOpticExpansion,
  ),
);

export interface AvailabilityOptionSelectProps {
  onChangeAddressClick?(): void;
  isEmbedded?: boolean;
}

const AvailabilityOptionSelect: React.FC<AvailabilityOptionSelectProps> = ({
  onChangeAddressClick,
  isEmbedded = false,
}) => {
  const { isYoung } = useOrder();
  const { closeModal } = useModalContext();
  const { availabilityType, templateId, manualCheck } =
    useAvailabilityContext();
  const { availability } = useAvailability();
  const {
    address,
    promotions: { presalePromotion, order, tvPromotions },
    objectInformation,
    isFTTHPlanned,
    maxDownload,
    planned,
  } = availability;

  const mandantType = checkMandantType(getEnv().SITE, address?.mandant);

  const { eventLinks, eventResult } = pushAvailability();
  const { zipCode } = address;
  const isConnected = maxDownload > 0;
  const availabilityStatus = presalePromotion
    ? isConnected
      ? AvailabilityStatus.AvailablePresale
      : AvailabilityStatus.PresaleOnly
    : AvailabilityStatus.Available;

  const availablePromotion = order?.find(
    (promotion) => promotion.maxDownload === maxDownload,
  );

  const portfolioName =
    availablePromotion?.portfolio === 'FIBER' ? 'Glasfaser' : 'DSL/Kabel' || '';

  const isCableTV = !isYoung && hasCableTV(tvPromotions || []);

  const handleButtonClick = (targetUrl: string) => {
    eventLinks({
      event: DataLayerEvents.PlanLink,
      status: availabilityStatus,
      zipCode: address.zipCode,
      checkSource: availabilityType,
      maxDownload: maxDownload,
      targetPage: targetUrl,
    });
    closeModal();
  };

  const e2e = planned
    ? `Presale-${planned.maxAvailableDownload}-${
        presalePromotion?.technology
      }-${maxDownload}-0-${planned.plannedAvailabilityDateDescription.replace(
        ' ',
        '',
      )}-${zipCode}-0-presaleavailable`
    : `${maxDownload}-0-${zipCode}`;

  useEffect(() => {
    eventResult({
      event:
        mandantType !== 'match'
          ? DataLayerEvents.ResultMandantMismatch
          : DataLayerEvents.Result,
      status: availabilityStatus,
      expansionStatus: objectInformation?.status || null,
      checkSource: availabilityType,
      checkStatus: manualCheck ? 'nicht-geprueft' : 'bereits-geprueft',
      zipCode: address.zipCode,
      maxDownload: maxDownload,
      presaleAvailability: !!planned,
      ...(planned && {
        plannedAvailabilityDate: planned.plannedAvailabilityDateDescription,
      }),
      ...(tvPromotions && { tvPromotions }),
      wholebuy: objectInformation?.wholebuy || null,
    });
  }, [mandantType]);

  const isFTTHOnly = isFTTHPlanned && !isConnected;
  const hasGfastPromotion =
    presalePromotion?.technology === Technology.GFAST &&
    availability?.objectInformation?.status === ExpansionStatus.HOMES_PREPARED;

  const PlannedAvailabilityText = () => {
    let text = (
      <>
        Bis voraussichtlich{' '}
        <span className={styles.strong}>
          {planned.plannedAvailabilityDateDescription}
        </span>{' '}
        ist der Glasfaser-Ausbau an deiner Adresse abgeschlossen.{' '}
        {isConnected ? 'Dann' : 'Nach Abschluss'} kannst du glasfaserschnell mit
        bis zu{' '}
        <span className={styles.strong}>
          {planned.maxAvailableDownload} Mbit/s
        </span>{' '}
        surfen.
      </>
    );

    if (hasGfastPromotion) {
      text = (
        <>
          Der Glasfaserausbau ist bereits abgeschlossen. Wenn du jetzt
          bestellst, kannst du ab{' '}
          <span className={styles.strong}>
            {planned.plannedAvailabilityDateDescription}
          </span>{' '}
          glasfaserschnell mit bis zu{' '}
          <span className={styles.strong}>
            {planned.maxAvailableDownload} Mbit/s
          </span>{' '}
          surfen.
        </>
      );
    }

    return (
      <Text
        e2e="available-fibre-optics-promotion-info-text"
        appearance="t4"
        block
      >
        {text}
      </Text>
    );
  };

  return isFTTHOnly ? (
    <FTTHPlannedFallback
      address={address}
      hasPreselectedProduct={!!templateId}
      onChangeAddressClick={onChangeAddressClick}
    />
  ) : (
    <div id={`ac-${isEmbedded ? 'content' : 'ot'}-result`} data-e2e={e2e}>
      <Spacer b={1} r={4} block>
        <Text appearance="t2" color="blue">
          {isConnected
            ? 'Verfügbare Produkte an deiner Adresse'
            : 'Demnächst verfügbare Produkte an deiner Adresse'}
        </Text>
      </Spacer>
      <AddressRow
        address={address}
        onChangeAddressClick={onChangeAddressClick}
      />
      {isFTTHPlanned ? (
        <>
          <Spacer b={3} block>
            <FibreOpticExpansion
              buttonColor="blue"
              displayStyle="nextto"
              objectInformation={objectInformation}
              embedded={isEmbedded}
            />
          </Spacer>
          <Text appearance="t2_2">Jetzt bestellbar</Text>
          <Spacer t={1}>
            <Text e2e="available-promotion-info-text" appearance="t4">
              An deiner Adresse surfst du aktuell mit bis zu{' '}
              <span className={styles.strong}>
                {maxDownload} Mbit/s {portfolioName}
              </span>
              . Du willst noch mehr? Dann hol dir NetTV über Internet und die
              Telefon-Flat dazu.
            </Text>
          </Spacer>
        </>
      ) : (
        <Text e2e="available-promotion-info-text" appearance="t4">
          {isConnected && (
            <>
              An deiner Adresse surfst du aktuell mit bis zu{' '}
              <span className={styles.strong}>
                {maxDownload} Mbit/s {portfolioName}
              </span>
              .
            </>
          )}
          {planned && (
            <Spacer y={1} className={styles.infoText}>
              <Spacer r={2}>
                <IconAttention color="blue" width={24} height={24} />
              </Spacer>
              <PlannedAvailabilityText />
            </Spacer>
          )}{' '}
          {isConnected &&
            `Du willst noch mehr? Dann hol dir die Telefon-Flat und NetTV über ${
              isCableTV ? 'Kabel' : 'Internet'
            } dazu.`}
        </Text>
      )}
      <Spacer b={3} block />
      <ProductSelector
        e2e="ac"
        isEmbedded={isEmbedded}
        onPlanClick={handleButtonClick}
        portfolio={planned ? 'FIBER' : availablePromotion?.portfolio || 'FIBER'}
      />
    </div>
  );
};

export { AvailabilityOptionSelect };
